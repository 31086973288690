import React, { useState, useEffect } from 'react';

import CV from './CV';

function App() {

  const { width } = useWindowSize();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const experience = [
    { emoji: '🦓', year: '2024 —', nonLinkText: 'Web & App Developer, ', linkText: 'The Ehlers-Danlos Society', link: 'https://www.ehlers-danlos.com/', chips: [] },
    // { emoji: '🎹', year: '2024', nonLinkText: null, linkText: 'Piano Dash', link: 'https://pianodash.com/', chips: ['Godot'] },
    { emoji: '🎹', year: '2022, 2024', nonLinkText: null, linkText: 'Piano Dash', link: 'https://pianodash.com/', chips: ['iOS', 'Android', 'Godot'] },
    { emoji: '🍱', year: '2024', nonLinkText: null, linkText: 'bentolingo', link: 'https://bentolingo.ai/', chips: ['React'] },
    { emoji: '⚽️', year: '2024', nonLinkText: null, linkText: 'Playset', link: 'https://playset.app/', chips: ['React', 'React Native'] },
    { emoji: '🪆', year: '2023', nonLinkText: null, linkText: 'LearnRussianCases', link: 'https://apps.apple.com/gb/app/learnrussiancases/id6450753430', chips: ['iOS'] },
    { emoji: '🧠', year: '2023', nonLinkText: null, linkText: 'Ebbinghaus', link: 'https://apps.apple.com/gb/app/ebbinghaus/id6450792114', chips: ['iOS'] },
    { emoji: '📱', year: '2022 — 2024', nonLinkText: 'iOS Developer, ', linkText: 'August', link: 'https://www.linkedin.com/company/theaugustapp/', chips: ['iOS'] },
    { emoji: '🇺🇸', year: '2022', nonLinkText: 'TA, University of Montana [lecturer, Java programming]', linkText: null, link: null, chips: [] },
    // { emoji: '🎹', year: '2022', nonLinkText: null, linkText: 'Piano Dash', link: 'https://pianodash.com/', chips: ['iOS', 'Godot'] },
    { emoji: '🇷🇺', year: '2021 — 2022', nonLinkText: null, linkText: 'LearnRussianCases.com', link: 'https://learnrussiancases.com/', chips: ['Vue'] },
    { emoji: '💡', year: '2021', nonLinkText: null, linkText: 'Leitnr', link: 'https://leitnr.com/', chips: ['React'] },
  ];

  // return (
  //   <CV />
  // );

  if (width > 800) {
    return (
      <div className="flex justify-center bg-backgroundPrimary w-screen h-screen font-wix text-sm text-textPrimary">
        <div className='flex flex-col'>

          <img src={`${process.env.PUBLIC_URL}/images/jmheader.png`} alt='Joseph Malam' className='w-[700px] mt-8 mb-2' />

          <div className='w-[700px] bg-backgroundSecondary border border-borderPrimary p-6 border-b-0 rounded-t-xl'>
            <div className='flex items-center -mb-1'>
              Hello, I'm a web and mobile developer from the UK <span className='font-notoEmoji ml-1'>🇬🇧</span>
            </div>
            {/* <div className='flex flex-row items-center mt-4 -mb-1'>
              <img src={`${process.env.PUBLIC_URL}/images/linkedIn.png`} alt='Joseph Malam' className='w-[18px] h-[18px] mr-2' />
              <a target="_blank" className='underline' href='https://www.linkedin.com/in/josephmalam/'>Contact me on LinkedIn</a>
            </div> */}
          </div>

          <div className='w-[700px] bg-backgroundSecondary border border-borderPrimary px-6 py-3'>
            {experience.map((item) => (
              <div className='flex flex-row my-3' key={item.name}>
                <div className='font-notoEmoji w-[48px] text-[12pt]'>{item.emoji}</div>
                <div className='w-[128px]'>{item.year}</div>
                <div className='flex flex-row items-center'>
                  {item.nonLinkText}
                  {item.nonLinkText && (
                    <div>&nbsp;</div>
                  )}
                  <a target="_blank" className='underline' href={item.link}>{item.linkText}</a>
                  {item.chips.map((chip) => (
                    <div className={`flex flex-row font-robotoMono text-white rounded-sm text-[11px] font-bold ml-3 px-1 ${chip == "React" ? 'bg-reactChip' : chip == "React Native" ? "bg-reactNativeChip" : chip == "iOS" ? "bg-iosChip" : chip == "Godot" ? "bg-godotChip" : chip == "Android" ? "bg-androidChip" : 'bg-vueChip'}`} key={chip}>
                      {chip}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className='w-[700px] bg-backgroundSecondary border border-borderPrimary px-6 py-3 border-t-0 rounded-b-xl'>
            <div className='flex flex-row my-3'>
              <div className='font-notoEmoji w-[48px] text-[12pt]'>🎓</div>
              <div className='w-[128px]'>2020 — 2021</div>
              <div className='flex flex-row items-center'>
                MSc Computer Science, Swansea University [Distinction, 89%]
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center bg-backgroundPrimary w-full h-full font-wix text-sm text-textPrimary">
        <div className='flex flex-col mx-2 mb-2'>

          <img src={`${process.env.PUBLIC_URL}/images/jmheader.png`} alt='Joseph Malam' className='w-full mt-4 mb-2' />

          <div className='w-full bg-backgroundSecondary border border-borderPrimary p-6 border-b-0 rounded-t-xl'>
            Hi, I'm a web and mobile developer from the UK <span className='font-notoEmoji'>🇬🇧</span>
          </div>

          <div className='w-full bg-backgroundSecondary border border-borderPrimary px-6 py-2'>
            {experience.map((item) => (
              <div className='flex flex-col my-4' key={item.name}>
                <div className='flex flex-row'>
                  <div className='font-notoEmoji mr-2'>{item.emoji}</div>
                  <div className=''>{item.year}</div>
                </div>
                <div className='flex flex-row items-center'>
                  {item.nonLinkText}
                  {item.nonLinkText && (
                    <div>&nbsp;</div>
                  )}
                  <a target="_blank" className='underline' href={item.link}>{item.linkText}</a>
                  {item.chips.map((chip) => (
                    <div className={`flex flex-row font-robotoMono text-white rounded-sm text-[11px] font-bold ml-3 px-1 ${chip == "React" ? 'bg-reactChip' : chip == "React Native" ? "bg-reactNativeChip" : chip == "iOS" ? "bg-iosChip" : chip == "Godot" ? "bg-godotChip" : chip == "Android" ? "bg-androidChip" : 'bg-vueChip'}`} key={chip}>
                      {chip}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className='w-full bg-backgroundSecondary border border-borderPrimary px-6 py-3 border-t-0 rounded-b-xl'>
            <div className='flex flex-col my-3'>
              <div className='flex flex-row'>
                <div className='font-notoEmoji mr-2'>🎓</div>
                <div className=''>2020 — 2021</div>
              </div>
              <div className='flex flex-row items-center'>
                MSc Computer Science, Swansea University [Distinction, 89%]
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default App;
